import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Dialog,
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Slide,
  InputAdornment,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { colors } from '../../../../themes/theme';

// Slide transition for the dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TrxModal = ({
  open,
  handleClose,
  transaction,
  onSave,
  categories,
  categoryGroups,
  transactionCategoryColorMap,
}) => {
  const [formData, setFormData] = useState({
    date: dayjs().format('YYYY-MM-DD'),
    description: '',
    amount: '',
    account: 'Other',
    category: '',
  });

  // Reset form when modal opens/closes
  useEffect(() => {
    if (transaction) {
      setFormData({
        date: transaction.date || dayjs().toISOString(),
        description: transaction.description || '',
        amount: transaction.amount?.toString() || '',
        account: 'Other',
        category: transaction.category || '',
      });
    } else {
      setFormData({
        date: dayjs().toISOString(),
        description: '',
        amount: '',
        account: 'Other',
        category: '',
      });
    }
  }, [transaction, open]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the user is changing the category, adjust the amount sign
    if (name === 'category' && formData.amount !== '') {
      const selectedCategory = value;
      const currentAmount = parseFloat(formData.amount);

      // Skip if amount is zero or not a valid number
      if (isNaN(currentAmount) || currentAmount === 0) {
        setFormData((prev) => ({ ...prev, [name]: value }));
        return;
      }

      // Check if it's an income or expense category
      const isIncomeCategory = categories.incomeCategories.some(
        (cat) => cat.value === selectedCategory
      );

      const isExpenseCategory = categories.expenseCategories.some(
        (cat) => cat.value === selectedCategory
      );

      let newAmount = currentAmount;

      // Adjust amount sign based on category type
      if (isIncomeCategory && currentAmount < 0) {
        newAmount = Math.abs(currentAmount);
      } else if (isExpenseCategory && currentAmount > 0) {
        newAmount = -Math.abs(currentAmount);
      }

      setFormData((prev) => ({
        ...prev,
        [name]: value,
        amount: newAmount.toString(),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDateChange = (date) => {
    if (!date) return;
    const newDate = dayjs(date);

    if (transaction && transaction.date) {
      const originalDate = dayjs(transaction.date);

      // Maintain time component from original date
      const combinedDate = newDate
        .hour(originalDate.hour())
        .minute(originalDate.minute())
        .second(originalDate.second())
        .millisecond(originalDate.millisecond());

      // Ensure we store a proper ISO string
      setFormData((prev) => ({
        ...prev,
        date: combinedDate.toISOString(),
      }));
    } else {
      const currentTime = dayjs();
      const combinedDate = newDate
        .hour(currentTime.hour())
        .minute(currentTime.minute())
        .second(currentTime.second())
        .millisecond(currentTime.millisecond());

      // Ensure we store a proper ISO string
      setFormData((prev) => ({
        ...prev,
        date: combinedDate.toISOString(),
      }));
    }
  };

  const handleCancel = () => {
    handleClose(true);
  };

  const handleSubmit = () => {
    if (!formData.description || formData.amount === '' || !formData.category) {
      alert('Please fill out all fields.');
      return;
    }

    const category = categories.allCategories.find(
      (cat) => cat.value === formData.category
    );

    const submitData = {
      ...formData,
      category: formData.category,
      category_id: category?.id || null,
      amount: parseFloat(formData.amount),
      date: formData.date,
      authorized_date: formData.date,
    };

    onSave(submitData);
  };

  const categoryOptions = useMemo(() => {
    // First, show income categories
    const incomeOptions = [
      { value: '__header_INCOME', label: 'INCOME', disabled: true },
      ...(categories?.incomeCategories
        ?.filter((cat) => cat.label !== 'Income')
        .map((category) => ({
          value: category.value,
          label: category.label,
          color: transactionCategoryColorMap[category.value],
        })) || []),
    ];

    // Then show expense categories grouped by their categories
    const expenseOptions =
      categoryGroups['expense']
        ?.map((group) => [
          // Header for each expense group
          {
            value: `__header_${group.title}`,
            label: group.title,
            disabled: true,
          },
          // Map categories with their colors from the categoriesColorMap
          ...group.categories.map((categoryName) => {
            // Find the matching category from expenseCategories to get the correct value
            const matchingCategory = categories.expenseCategories.find(
              (cat) => cat.value === categoryName || cat.label === categoryName
            );
            return {
              value: matchingCategory?.value || categoryName,
              label: categoryName,
              color:
                transactionCategoryColorMap[
                  matchingCategory?.value || categoryName
                ],
            };
          }),
        ])
        .flat() || [];

    // Combine both income and expense categories
    return [...incomeOptions, ...expenseOptions];
  }, [categoryGroups, categories, transactionCategoryColorMap]);

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: '#f8f9fa',
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: '#f0f1f2',
      },
      '&.Mui-focused': {
        backgroundColor: '#ffffff',
        boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.1)',
      },
    },
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'white',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 2,
          borderBottom: '1px solid rgba(0,0,0,0.1)',
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: 600, color: colors.primary }}
        >
          {transaction ? 'Edit Transaction' : 'New Transaction'}
        </Typography>
        <IconButton onClick={handleCancel} size="large">
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Form Content */}
      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <MobileDatePicker
          label="Date"
          value={dayjs(formData.date)}
          onChange={handleDateChange}
          slotProps={{
            textField: {
              sx: { ...inputStyles },
              fullWidth: true,
              inputFormat: 'YYYY-MM-DD',
            },
          }}
        />
        <TextField
          label="Amount"
          type="number"
          value={formData.amount}
          onChange={handleChange}
          name="amount"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          sx={{ ...inputStyles }}
        />
        <TextField
          label="Description"
          value={formData.description}
          onChange={handleChange}
          name="description"
          fullWidth
          sx={{ ...inputStyles }}
        />
        <TextField
          label="Account"
          value={formData.account}
          InputProps={{ readOnly: true }}
          fullWidth
          sx={{ ...inputStyles }}
        />
        <FormControl fullWidth sx={{ ...inputStyles }}>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            value={formData.category || ''}
            onChange={handleChange}
            name="category"
            label="Category"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {categoryOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                sx={{
                  py: 1.5,
                  ...(option.disabled
                    ? {
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                        fontWeight: 600,
                        color: colors.primary,
                      }
                    : {
                        pl: 3,
                      }),
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          p: 2,
          borderTop: '1px solid rgba(0,0,0,0.1)',
        }}
      >
        <Button
          fullWidth
          variant="outlined"
          onClick={handleCancel}
          sx={{
            borderRadius: '12px',
            py: 1.5,
            borderColor: colors.primary,
            color: colors.primary,
          }}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{
            borderRadius: '12px',
            py: 1.5,
            bgcolor: colors.primary,
          }}
        >
          {transaction ? 'Update' : 'Save'}
        </Button>
      </Box>
    </Dialog>
  );
};

export default TrxModal;
